import React, { Component } from 'react';

import fondo from '../../assets/img/carrusel/logo-sm.png';
import imagen3 from '../../assets/img/carrusel/banner-sm-img.png';
import imagen4 from '../../assets/img/carrusel/banner-sm-right-bar.png';
import banner1 from '../../assets/img/carrusel/BANNER_SISSA_MONITORING_1.png';
import bannerMobile3 from '../../assets/img/carrusel/BANNER-SISSA-MONITORING-03-MOBILE.jpg';
import banner3 from '../../assets/img/carrusel/BANNER_SISSA_MONITORING_3.png';
import bannerMobile1 from '../../assets/img/carrusel/MobileSISSA.png';
import banner2 from '../../assets/img/carrusel/BANNER_SISSA_MONITORING_2.png';
import bannerMobile2 from '../../assets/img/carrusel/Mobile.jpeg';
import banner5 from '../../assets/img/carrusel/BANNER_SISSA_MONITORING_5.png';

import '../../stylesheets/Pages/animations.css';
import '../../stylesheets/Pages/swiper-bundle.min.css';
import '../../stylesheets/Pages/inicioCarrusel.css';

class CarruselNuevo extends Component {
    state = {
        width: 0,
    };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
        const { width } = this.state;
        return (
            <div className='swiper-container'>
                <div className='espacioArribaCarrusel'></div>
                {width > 426 ? (
                    <div className='swiper-wrapper'>
                        <div className='swiper-slide slide-bg'>
                            <div className='content-slide'>
                                <img src={banner1} alt='IMG' width={'100%'} />
                            </div>
                        </div>

                        <div className='swiper-slide slide-bg'>
                            <div className='content-slide'>
                                <img src={banner2} alt='img' width={'100%'} />
                            </div>
                        </div>
                        <div className='swiper-slide slide-bg'>
                            <div className='content-slide'>
                                <img src={banner3} alt='img' width={'100%'} />
                            </div>
                        </div>
                        {/* <div className="swiper-slide">
                        <div className="background-img banner-sm-bg"></div>
                        <div className="content-slide">
                            <div className="content-slide-left banner-sm-left">
                                <div className="banner-sm-logo">  <img alt='img' className="" src={fondo} /> </div>
                                <img alt='img' className="banner-sm-img" src={imagen3} />
                            </div>
                            <div className="content-slide-right banner-sm-right">
                                <div className="logo-and-text-container">
                                    <div className="banner-sm-logo">  <img alt='img' className="" src={fondo} /> </div>
                                    <div className="banner-sm-text-container">
                                        <div className="banner-sm-text-1">IMPLEMENTAMOS</div>
                                        <div className="banner-sm-text-2">CENTRO DE MONITOREO</div>
                                        <div className="banner-sm-text-3">EN TU EMPRESA O INDUSTRIA</div>
                                    </div>
                                </div>
                                <div className="banner-sm-right-bar">
                                    <img alt='img' src={imagen4} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                        <div className='swiper-slide slide-bg'>
                            <div className='content-slide'>
                                <img src={banner5} alt='img' width={'100%'} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='swiper-wrapper'>
                        <div className='swiper-slide slide-bg'>
                            <div className='content-slide'>
                                <img src={bannerMobile1} alt='IMG' width={'100%'} />
                            </div>
                        </div>

                        <div className='swiper-slide slide-bg'>
                            <div className='content-slide'>
                                <img src={bannerMobile2} alt='img' width={'100%'} />
                            </div>
                        </div>
                    </div>
                )}
                <div className='swiper-pagination'></div>
                <div className='swiper-button-prev'></div>
                <div className='swiper-button-next'></div>
                <div className='swiper-scrollbar'></div>
            </div>
        );
    }
}

export default CarruselNuevo;
